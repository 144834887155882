<template>
  <v-container>
    <div v-if="!isSubmitted">
      <h2 class="header header-span mb-8">Reset your password</h2>
      <v-row class="d-flex justify-center">
        <v-col>
          <v-form
              @submit.prevent="submit"
              ref="form"
              v-model="valid"
          >
            <div class="prompt mb-lg-5">
              Enter your email address below and we'll send you a link to reset your password.
            </div>
            <TextField
                label="Email address"
                class="mb-lg-6"
                :rules="rules.email"
                v-model="model.email"
            />
            <v-btn
                block
                color="secondary"
                type="submit"
                x-large
                class="mb-lg-6"
            >
            <span slot="loader">
              <v-progress-circular
                  indeterminate
                  color="primary"
              />
            </span>
              <span class="btn__text btn__text--white">
                Send reset password email
              </span>
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <div v-if="isSubmitted">
      <h2 class="header header-span mb-8">Check your email</h2>
      <div class="prompt mb-lg-5">
        An email will be sent to this email address if we have an account that is registered with it.
      </div>
    </div>
  </v-container>
</template>

<script>

import TextField from '@/components/TextField.vue'
import Http from '@/api/Http';

export default {
  name: 'AuthForgot',
  components: { TextField },
  data() {
    return {
      api: Http.use('data'),
      isSubmitted: false,
      valid: true,
      model: {
        email: ''
      },
      rules: {
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      },
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.api.post('/auth/forgot', { email: this.model.email })
            .then(() => {
              this.isSubmitted = true;
            })
            .catch(() => {
              this.isSubmitted = true;
            });
      }
    }
  }
}
</script>

<style scoped>
.header {
  color: var(--v-secondary-base);
  text-align: center;
  line-height: 100%;
}
.prompt {
  line-height: 150%;
  font-size: 1.5rem;
}
</style>